import { IUserProfileBond, IUserProfileBondDynamicResponse } from '__generated__/api';
import * as dynamic from 'utils/dynamic';
import { decoratorIsNotNullable, equals } from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  RTK_TAGS,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  GetUserProfileBondAlertsInput,
  GetUserProfileBondAlertsOutput,
  GetUserProfileBondsForProfileInput,
  GetUserProfileBondsForProfileItem,
  IUserProfileBondArg,
} from './models';

export * from './models';

const requestGet = API.api.userProfileBondsGetAllDynamicList;
const requestPatch = API.api.userProfileBondsPatchPartialUpdate;

export const apiUserProfileBonds = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserProfileGridBonds: build.query<IUserProfileBondDynamicResponse, IUserProfileBondArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, ...filters }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'bond.symbol as symbol',
              'bond.name as name',
              'bond.controllingShareHolder as controllingShareHolder',
              'bond.issuerNum as issuerNum',
              'bond.equityTypeID as equityTypeID',
              'bond.linkedAssetID as linkedAssetID',
              'bond.tradingStage as tradingStage',
              'bond.issuerID as issuerID',
              'bond.branchID as branchID',
              'bond.lastRateDate as lastRateDate',
              'bond.flowYear1 as flowYear1',
              'bond.flowYear2 as flowYear2',
              'bond.flowYear3 as flowYear3',
              'bond.flowYear4 as flowYear4',
              'bond.flowYear5 as flowYear5',
              'bond.flowYear6 as flowYear6',
              'bond.flowYear7 as flowYear7',
              'bond.flowYearElse as flowYearElse',
              'bond.issuer.title as issuerName',
              'bond.equityType.title as equityTypeName',
              'bond.branch.title as branchName',
              'bond.baseRate as baseRate',
              'bond.lastDealRate as lastDealRate',
              'bond.baseRateChangePercentage as baseRateChangePercentage',
              'bond.yesterdayYield as yesterdayYield',
              'bond.beginOfYearYield as beginOfYearYield',
              'bond.grossDuration as grossDuration',
              'bond.grossYieldToMaturityDeltaByDuration as grossYieldToMaturityDeltaByDuration',
              'bond.linkedAsset.title as linkedAssetName',
              'bond.annualInterest as annualInterest',
              'bond.bondMidroogRank as bondMidroogRank',
              'bond.bondMidroogOutlook as bondMidroogOutlook',
              'bond.bondMaalotRank as bondMaalotRank',
              'bond.bondMaalotOutlook as bondMaalotOutlook',
              'bond.weekAverageNIS as weekAverageNIS',
              'bond.dailyTurnover as dailyTurnover',
              'bond.grossAdjustedValue as grossAdjustedValue',
              'bond.listedFortune as listedFortune',
              'bond.redemptionOrExpirationDate as redemptionOrExpirationDate',
              'bond.issuingDate as issuingDate',
              'bond.grossYieldToMaturity as grossYieldToMaturity',
            ),
            OrderBy: dynamic.orderBy(orderBy),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('userProfileID', filters.userProfileID, dynamic.equals),
                dynamic.makeFilter(
                  'symbol',
                  filters.bonds,
                  dynamic.decoratorValueArray(dynamic.decoratorStringify(dynamic.equals)),
                ),
                dynamic.makeFilter(
                  'branchID',
                  filters.branchIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'linkedAssetID',
                  filters.linkedAssetIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'issuerID',
                  filters.issuerIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'equityTypeID',
                  filters.equityTypeIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'maalotRatingID',
                  filters.maalotRatingIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'midroogRatingID',
                  filters.midroogRatingIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter('isActive', filters.isActive, decoratorIsNotNullable(equals)),
              )
              .join('&&'),
            Take,
            Skip,
            Count: true,
          });
          return res;
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getUserProfileBondAlerts: build.query<
      GetUserProfileBondAlertsOutput,
      GetUserProfileBondAlertsInput
    >({
      queryFn: async ({ userProfileID, bondID }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'bondID',
              'userProfileID',
              'alert1',
              'alert2',
              'alert3',
              'alert4',
              'alert5',
              'alert6',
              'alert7',
              'alert8',
              'bond.symbol',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('userProfileID', userProfileID, dynamic.equals),
                dynamic.makeFilter('bondID', bondID, dynamic.equals),
              )
              .join('&&'),
            Take: 1,
          });
          return rtkAdapterDynamicItem<GetUserProfileBondAlertsOutput>(res as unknown as any);
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getUserProfileBondsForProfile: build.query<
      Array<GetUserProfileBondsForProfileItem>,
      GetUserProfileBondsForProfileInput
    >({
      queryFn: async ({ userProfileID }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'bondID',
              'userProfileID',
              'alert1',
              'alert2',
              'alert3',
              'alert4',
              'alert5',
              'alert6',
              'alert7',
              'alert8',
              'bond.symbol',
              'bond.name',
              'bond.issuerName',
              'bond.issuerID',
              'bond.controllingShareHolder',
              'bond.issuerNum',
              'bond.equityTypeName',
              'bond.branchName',
              'bond.baseRate',
              'bond.lastDealRate',
              'bond.baseRateChangePercentage',
              'bond.yesterdayYield',
              'bond.beginOfYearYield',
              'bond.grossYieldToMaturity',
              'bond.grossDuration',
              'bond.grossYieldToMaturityDeltaByDuration',
              'bond.linkedAssetName',
              'bond.weekAverageNIS',
              'bond.dailyTurnover',
              'bond.annualInterest',
              'bond.grossAdjustedValue',
              'bond.listedFortune',
              'bond.redemptionOrExpirationDate',
              'bond.issuingDate',
              'bond.lastRateDate',
              'bond.tradingStage',
              'bond.bondMidroogRank',
              'bond.bondMidroogOutlook',
              'bond.bondMaalotRank',
              'bond.bondMaalotOutlook',
              'bond.flowYear1',
              'bond.flowYear2',
              'bond.flowYear3',
              'bond.flowYear4',
              'bond.flowYear5',
              'bond.flowYear6',
              'bond.flowYear7',
              'bond.flowYearElse',
            ),
            Filter: dynamic
              .mergeFilters(dynamic.makeFilter('userProfileID', userProfileID, dynamic.equals))
              .join('&&'),
          });
          const { data } = rtkAdapterDynamicToSource<GetUserProfileBondsForProfileItem>(
            res as unknown as any,
          );
          return { data };
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      providesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),
    patchUserProfileBondAlert: build.mutation<
      void,
      PatchPartial<IUserProfileBond, 'id' | 'userProfileID' | 'bondID'>
    >({
      queryFn: async (input) => {
        try {
          const { userProfileID, bondID, ...rest } = input;
          await requestPatch(...makePatchArgs(rest));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.BONDS_HELPERS, id: 'user-bonds' }],
    }),
  }),
});
