import * as dynamic from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  RTK_TAGS,
  rtkAdapterDynamicItem,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import {
  GetUserProfileStockAlertsInput,
  GetUserProfileStockAlertsOutput,
  UserProfileStockArg,
} from './models';

import { IUserProfileStock, IUserProfileStockDynamicResponse } from '__generated__/api';
import { decoratorIsNotNullable, equals } from 'utils/dynamic';

export * from './models';

const requestGet = API.api.userProfileStocksGetAllDynamicList;
const requestPatch = API.api.userProfileStocksPatchPartialUpdate;

export const apiUserProfileStocks = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridUserProfileStocks: build.query<IUserProfileStockDynamicResponse, UserProfileStockArg>({
      queryFn: async ({ take: Take, skip: Skip, orderBy, ...filters }) => {
        try {
          return await requestGet({
            Select: dynamic.select(
              'id',
              'stock.symbol as symbol',
              'stock.name as name',
              'stock.controllingShareHolder as controllingShareHolder',
              'stock.issuerNum as issuerNum',
              'stock.issuer.title as issuerName',
              'stock.equityType.title as equityTypeName',
              'stock.branch.title as branchName',
              'stock.baseRate as baseRate',
              'stock.lastDealRate as lastDealRate',
              'stock.baseRateChangePercentage as baseRateChangePercentage',
              'stock.weekYield as weekYield',
              'stock.allMonthYield as allMonthYield',
              'stock.beginOfYearYield as beginOfYearYield',
              'stock.allYearYield as allYearYield',
              'stock.pbRatio as pbRatio',
              'stock.currentMarketWorth as currentMarketWorth',
              'stock.weekAverageTurnover as weekAverageTurnover',
              'stock.dailyTurnover as dailyTurnover',
              'stock.listedFortune as listedFortune',
              'stock.dividendYield as dividendYield',
              'stock.peRetio as peRetio',
              'stock.yesterdayYield as yesterdayYield',
              'stock.issuingDate as issuingDate',
              'stock.lastRateDate as lastRateDate',
              'stock.tradingStage as tradingStage',
              'stock.issuerID as issuerID',
              'stock.branchID as branchID',
              'stock.equityTypeID as equityTypeID',
              'stock.tradeStatusID as tradeStatusID',
            ),
            OrderBy: dynamic.orderBy(orderBy),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('userProfileID', filters.userProfileID, dynamic.equals),
                dynamic.makeFilter(
                  'symbol',
                  filters.stocks,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'equityTypeID',
                  filters.equityTypeIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter(
                  'branchID',
                  filters.branchIDs,
                  dynamic.decoratorValueArray(dynamic.equals),
                ),
                dynamic.makeFilter('isActive', filters.isActive, decoratorIsNotNullable(equals)),
              )
              .join('&&'),
            Take,
            Skip,
            Count: true,
          });
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    getUserProfileStockAlerts: build.query<
      GetUserProfileStockAlertsOutput,
      GetUserProfileStockAlertsInput
    >({
      queryFn: async ({ userProfileID, stockID }) => {
        try {
          const res = await requestGet({
            Select: dynamic.select(
              'id',
              'stockID',
              'userProfileID',
              'stock.symbol',
              'alert1',
              'alert2',
              'alert3',
              'alert4',
              'alert5',
              'alert6',
              'alert7',
              'alert8',
              'alert10',
              'alert11',
              'alert12',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('userProfileID', userProfileID, dynamic.equals),
                dynamic.makeFilter('stockID', stockID, dynamic.equals),
              )
              .join('&&'),
            Take: 1,
          });
          return rtkAdapterDynamicItem<GetUserProfileStockAlertsOutput>(res as unknown as any);
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
    patchUserProfileStockAlert: build.mutation<
      void,
      PatchPartial<IUserProfileStock, 'id' | 'userProfileID' | 'stockID'>
    >({
      queryFn: async (input) => {
        try {
          const { userProfileID, stockID, ...rest } = input;
          await requestPatch(...makePatchArgs(rest));
          return { data: undefined };
        } catch (e) {
          return rtkAdapterError(e as any);
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.STOCKS_HELPERS, id: 'user-stocks' }],
    }),
  }),
});
